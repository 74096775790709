import { AccommodationType, BandReader, Resort, Tablet } from '@models/booking'
import { EservicePinPad } from '@models/eservice'
import { PaymentDayUserReport } from '@models/payments'
import { ReceptionWorkstation } from '@models/reception'
import { Receipt } from '@models/shop'
import { Group } from '@models/account'
import { BaseUrls } from '@models/base'
import { Unit, UnitKind } from '@models/housekeeping'

export enum UserPermission {
  BenefitProgramsAgreementsCanCreate = 'dashboard.benefit_programs_agreements_can_create',
  BenefitProgramsCanAddPayment = 'dashboard.benefit_programs_can_add_payment',
  GenericVoucherBocianCanIssuePromocodes = 'dashboard.generic_voucher_bocian_can_issue_promocodes',
  PackageCanChange = 'dashboard.can_change_package',
  PackageHpiCanChange = 'dashboard.can_change_package_hpi',
  PackageCanChangeCodeName = 'dashboard.can_change_package_code_name',
  PackageHpiCanChangeCodeName = 'dashboard.can_change_package_code_name_hpi',
  PackageCanChangeCodeActive = 'dashboard.can_change_package_code_active',
  PackageHpiCanChangeCodeActive = 'dashboard.can_change_package_code_active_hpi',
  ApartmentCanBlockApartment = 'dashboard.apartment_can_block_apartment',
  ApartmentCanRemoveBlockApartment = 'dashboard.apartment_can_remove_block_apartment',
  BarrierBarrierticketCanAdd = 'dashboard.barrier_barrierticket_can_add',
  BarrierBarrierticketCanDelete = 'dashboard.barrier_barrierticket_can_delete',
  BarrierBarrierticketCanUpdate = 'dashboard.barrier_barrierticket_can_update',
  BarrierBarrierticketCanView = 'dashboard.barrier_barrierticket_can_view',
  BarrierBarrierticketSpecialCanAdd = 'dashboard.barrier_barrierticket_special_can_add',
  BarrierBarrierticketSpecialCanDelete = 'dashboard.barrier_barrierticket_special_can_delete',
  BarrierBarrierticketSpecialCanUpdate = 'dashboard.barrier_barrierticket_special_can_update',
  BookingCalendarCanViewAllResorts = 'dashboard.booking_calendar_can_view_all_resorts',
  BookingCanAddCashPayment = 'dashboard.booking_can_add_cash_payment',
  BookingCanAddCreditcardPayment = 'dashboard.booking_can_add_creditcard_payment',
  BookingCanAddFeeding = 'dashboard.booking_can_add_feeding',
  BookingCanAddWineImprovement = 'dashboard.booking_can_add_wine_improvement',
  BookingCanChangeExtendedStayPrice = 'dashboard.booking_can_change_extended_stay_price',
  BookingCanChangeFeeding = 'dashboard.booking_can_change_feeding',
  BookingCanIgnoreDatesRestrictions = 'dashboard.booking_can_ignore_dates_restrictions',
  BookingCanIgnoreLocalizationRestrictions = 'dashboard.booking_can_ignore_localization_restrictions',
  BookingCanManageSmartWatchUUID = 'dashboard.booking_can_manage_smart_watch_uuid',
  BookingCanRemovePromocode = 'dashboard.booking_can_remove_promocode',
  BookingCanSetPromocode = 'dashboard.booking_can_set_promocode',
  BookingCanSkipClimaticTax = 'dashboard.booking_can_skip_climatic_tax',
  BookingCanViewDetails = 'dashboard.booking_can_view_details',
  BookingCanViewIdCard = 'dashboard.booking_can_show_id_card',
  BookingCanAddSubscriptionBookingImprovement = 'dashboard.booking_can_add_subscription_booking_improvement',
  BookingCheckInRestore = 'dashboard.booking_check_in_restore',
  BookingCheckInRestoreToday = 'dashboard.booking_check_in_restore_today',
  BookingCanChangeDefaultDepositAmount = 'dashboard.booking_can_change_default_deposit_amount',
  BookingFeedingCanAdd = 'dashboard.booking_feeding_can_add',
  BookingFeedingCanChange = 'dashboard.booking_feeding_can_change',
  BookingFeedingCanChangeFixedPrice = 'dashboard.booking_feeding_can_change_fixed_price',
  BookingGuestsCanRemoveWithFeeding = 'dashboard.booking_guests_can_remove_with_feeding',
  BookingImprovementStatisticsCanViewAllResorts = 'dashboard.booking_improvement_statistics_can_view_all_resorts',
  BookingStatisticsCanViewAllResorts = 'dashboard.booking_statistics_can_view_all_resorts',
  CanCreateReservation = 'dashboard.can_create_reservation',
  CanResendToSage = 'dashboard.can_resend_to_sage',
  CanViewSpecialReservation = 'dashboard.can_view_special_reservation',
  CashPaymentDayReportCanClose = 'dashboard.cash_payment_day_report_can_close',
  CashPaymentDayReportCanReopen = 'dashboard.cash_payment_day_report_can_reopen',
  CashPaymentDayReportCanViewHistory = 'dashboard.cash_payment_day_report_can_view_history',
  CashPaymentDayUserReportCanChangeStartAmount = 'dashboard.cash_payment_day_user_report_can_change_start_amount',
  CashPaymentMoveReportRowCanView = 'dashboard.cash_payment_move_report_row_can_view',
  CashPaymentRegisterCanView = 'dashboard.cash_payment_register_can_view',
  ClientCanChangeRules = 'dashboard.client_can_change_rules',
  ClientCanChangeServicesRulesObjections = 'dashboard.client_can_change_services_rules_objections',
  ClientCanSkipServiceCharge = 'dashboard.client_can_skip_service_charge',
  ClientOptionPurchaseCanAddPayment = 'dashboard.client_option_purchase_can_add_payment',
  CrmRecommendationGiftCanChange = 'dashboard.crm_can_change_recommendation_gift',
  CrmRecommendationGiftCanDelete = 'dashboard.crm_can_remove_recommendation_gift',
  Dummy = 'Dummy',
  GuestsCanAddGuests = 'dashboard.guests_can_add_guests',
  EserviceCanUsePinPad = 'dashboard.eservice_can_use_pin_pad',
  EserviceCreditCardPreauthorizationCanCancel = 'dashboard.eservice_credit_card_preauthorization_can_cancel',
  FeedingBookingChange = 'dashboard.feeding_booking_change',
  FeedingCanAddDiscount = 'dashboard.booking_feeding_can_add_discount',
  FeedingCanChangeDiscount = 'dashboard.booking_feeding_can_change_discount',
  FeedingCanRemoveDiscount = 'dashboard.booking_feeding_can_remove_discount',
  FeedingChangeFeedingClient = 'feeding.change_feedingclient',
  FeedingChangeFeedingWorkerGuest = 'feeding.change_feedingworkerguest',
  FloorHeatingCanSetScenario = 'dashboard.meters_floor_heating_can_set_scenario',
  FloorHeatingCanSetTemperature = 'dashboard.meters_floor_heating_can_set_temperature',
  HasAccessToBookingInfo = 'dashboard.has_access_to_booking_info',
  HousekeepingCanChangeApartmentStatus = 'dashboard.housekeeping_can_change_apartment_status',
  HousekeepingCanEditAcceptedOrders = 'dashboard.housekeeping_can_edit_accepted_orders',
  HrAgreementCanChangeToAnyStatus = 'dashboard.hr_agreement_can_change_to_any_status',
  HrAgreementCanDownload = 'dashboard.hr_agreement_can_download',
  HrAgreementCanDownloadForAnyNationForAnyStatus = 'dashboard.hr_agreement_can_download_for_any_nation_for_any_status',
  HrAgreementCanDownloadInStatusDraft = 'dashboard.hr_agreement_can_download_in_status_draft',
  HrAgreementCanEdit = 'dashboard.hr_agreement_can_edit',
  HrAgreementCanEditDuringVerification = 'dashboard.hr_can_edit_during_verification',
  HrAgreementCanEditStartDateInVerifiedStatus = 'dashboard.hr_can_edit_start_date_in_verified_status',
  HrAgreementCanSelectAnyResort = 'dashboard.hr_agreement_can_select_any_resort',
  HrAgreementCanSelectHRAgents = 'dashboard.hr_agreement_can_select_hr_agents',
  HrAgreementCanSetRetroactiveDate = 'dashboard.dashboard.hr_can_set_retroactive_date',
  HrAgreementCanSetAnySalary = 'dashboard.hr_agreement_can_set_any_salary',
  HrAgreementCanVerify = 'dashboard.hr_can_verify_agreement',
  HrAgreementCanView = 'dashboard.hr_agreement_can_view',
  HrAgreementStatusCanRevoke = 'dashboard.hr_agreement_status_can_revoke',
  HrWorkerProfileCanRemove = 'dashboard.hr_worker_profile_can_remove',
  HrWorkerProfileCanRestore = 'dashboard.hr_worker_profile_can_restore',
  HrWorkerProfileCanViewRemoved = 'dashboard.hr_worker_profile_can_view_removed',
  HrWorkerProfileCanAdd = 'dashboard.hr_worker_profile_can_add_and_edit',
  IsBoss = 'dashboard.is_boss',
  NotificationCanManage = 'dashboard.notification_can_manage',
  PackageCanCreateHpiSourcePackage = 'dashboard.can_create_source_hpi_package',
  PackageCanDelete = 'dashboard.can_package_delete',
  PackageCanSetExpireAfter = 'dashboard.package_can_set_expire_after',
  PackageCanViewAll = 'dashboard.can_view_package_all',
  PackageCanViewGroup = 'dashboard.can_view_package_group',
  PackageHpiCanDelete = 'dashboard.can_package_delete_hpi',
  PackageHpiCanRemoveCode = 'dashboard.package_hpi_can_remove_code',
  PackageHpiCanViewAll = 'dashboard.can_view_package_all_hpi',
  PackageHpiCanViewGroup = 'dashboard.can_view_package_group_hpi',
  PackageWholesaleCanManageAll = 'dashboard.package_wholesale_can_manage_all',
  PackageWholesaleCanManageGroup = 'dashboard.package_wholesale_can_manage_group',
  PackageWholesaleCanSetExpireAfter = 'dashboard.package_wholesale_can_set_expire_after',
  PackageWholesaleCanSetPassword = 'dashboard.package_wholesale_can_set_password',
  PackageWholesaleCanViewAll = 'dashboard.package_wholesale_can_view_all',
  PackageWholesaleCanViewGroup = 'dashboard.package_wholesale_can_view_group',
  PackageWholesaleProductCanManage = 'dashboard.package_wholesale_product_can_manage',
  PackageWholesaleProductCanView = 'dashboard.package_wholesale_product_can_view',
  ProductsCafeteriaCanCreate = 'dashboard.products_cafeteria_can_create',
  ProductsCafeteriaCanDelete = 'dashboard.products_cafeteria_can_delete',
  PromotionsGastroCard2024CanChangeSellerInAnyVoucherStatus = 'dashboard.promotions_gastro_card_2024_can_change_seller_in_any_voucher_status',
  PromotionsGastroCard2024CanChangeSellerInInitialVoucherStatus = 'dashboard.promotions_gastro_card_2024_can_change_seller_in_initial_voucher_status',
  PromotionsGastroCard2025CanChangeSellerInAnyVoucherStatus = 'dashboard.promotions_gastro_card_2025_can_change_seller_in_any_voucher_status',
  PromotionsGastroCard2025CanChangeSellerInInitialVoucherStatus = 'dashboard.promotions_gastro_card_2025_can_change_seller_in_initial_voucher_status',
  PromotionsGastroCard2026CanChangeSellerInAnyVoucherStatus = 'dashboard.promotions_gastro_card_2026_can_change_seller_in_any_voucher_status',
  PromotionsGastroCard2026CanChangeSellerInInitialVoucherStatus = 'dashboard.promotions_gastro_card_2026_can_change_seller_in_initial_voucher_status',
  PromotionsGastroCard2026OctoberCanChangeSellerInAnyVoucherStatus = 'dashboard.promotions_gastro_card_2026_october_can_change_seller_in_any_voucher_status',
  PromotionsGastroCard2026OctoberCanChangeSellerInInitialVoucherStatus = 'dashboard.promotions_gastro_card_2026_october_can_change_seller_in_initial_voucher_status',
  PromotionsGastroCard2026NovemberCanChangeSellerInAnyVoucherStatus = 'dashboard.promotions_gastro_card_2026_november_can_change_seller_in_any_voucher_status',
  PromotionsGastroCard2026NovemberCanChangeSellerInInitialVoucherStatus = 'dashboard.promotions_gastro_card_2026_november_can_change_seller_in_initial_voucher_status',
  PromotionsGastroCard2027CanChangeSellerInAnyVoucherStatus = 'dashboard.promotions_gastro_card_2027_can_change_seller_in_any_voucher_status',
  PromotionsGastroCard2027CanChangeSellerInInitialVoucherStatus = 'dashboard.promotions_gastro_card_2027_can_change_seller_in_initial_voucher_status',
  PromotionsGastroCardDynamicCanChangeSellerInAnyVoucherStatus = 'dashboard.promotions_gastro_card_dynamic_can_change_seller_in_any_voucher_status',
  PromotionsGastroCardDynamicCanChangeSellerInInitialVoucherStatus = 'dashboard.promotions_gastro_card_dynamic_can_change_seller_in_initial_voucher_status',
  PromotionsGastroCardBoostCanChangeSellerInAnyVoucherStatus = 'dashboard.promotions_gastro_card_boost_can_change_seller_in_any_voucher_status',
  PromotionsGastroCardBoostCanChangeSellerInInitialVoucherStatus = 'dashboard.promotions_gastro_card_boost_can_change_seller_in_initial_voucher_status',
  PromotionsGastroCard20CanChangeSellerInAnyVoucherStatus = 'dashboard.promotions_gastro_card_20_can_change_seller_in_any_voucher_status',
  PromotionsGastroCard20CanChangeSellerInInitialVoucherStatus = 'dashboard.promotions_gastro_card_20_can_change_seller_in_initial_voucher_status',
  PromotionsGastroCardCanChangeDeclaredSellPrice = 'dashboard.promotions_gastro_card_can_change_declared_sell_price',
  PromotionsGastroCardCanChangeSellerEvenWww = 'dashboard.promotions_gastro_card_can_change_seller_even_www',
  PromotionsGastroCardCanChangeSellerInAnyVoucherStatus = 'dashboard.promotions_gastro_card_can_change_seller_in_any_voucher_status',
  PromotionsGastroCardCanChangeSellerInInitialVoucherStatus = 'dashboard.promotions_gastro_card_can_change_seller_in_initial_voucher_status',
  PromotionsGastroCardChristmas2024CanChangeSellerInAnyVoucherStatus = 'dashboard.promotions_gastro_card_christmas_2024_can_change_seller_in_any_voucher_status',
  PromotionsGastroCardChristmas2024CanChangeSellerInInitialVoucherStatus = 'dashboard.promotions_gastro_card_christmas_2024_can_change_seller_in_initial_voucher_status',
  PromotionsGastroCardCanCreateCode = 'dashboard.promotions_gastro_card_can_create_code',
  PromotionsGastroCardCanDelete = 'dashboard.promotions_gastro_card_can_delete',
  PromotionsGastroCardCanDeleteInvoice = 'dashboard.promotions_gastro_card_can_delete_invoice',
  PromotionsGastroCardCanDeleteItem = 'dashboard.promotions_gastro_card_can_delete_item',
  PromotionsGastroCardCanDeletePayment = 'dashboard.promotions_gastro_card_can_delete_payment',
  PromotionsGastroCardCanExport = 'dashboard.promotions_gastro_card_can_export',
  PromotionsGastroCardCanUpdateExpireDate = 'dashboard.promotions_gastro_card_can_update_expire_date',
  PromotionsGastroCardCanChangeUsageDate = 'dashboard.promotions_gastro_card_can_change_active_and_expire_date',
  PromotionsGastroCouponCanCancel = 'dashboard.promotions_gastro_coupon_can_cancel',
  PromotionsGastroCouponCanChangeDeclaredSellPrice = 'dashboard.promotions_gastro_coupon_can_change_declared_sell_price',
  PromotionsGastroCouponCanChangePaymentMethod = 'dashboard.promotions_gastro_coupon_can_change_payment_method',
  PromotionsGastroCouponCanChangeSellDate = 'dashboard.promotions_gastro_coupon_can_change_sell_date',
  PromotionsGastroCouponCanChangeSeller = 'dashboard.promotions_gastro_coupon_can_change_seller',
  PromotionsGastroCouponCanCreate = 'dashboard.promotions_gastro_coupon_can_create',
  PromotionsGastroCouponCanUpdate = 'dashboard.promotions_gastro_coupon_can_update',
  PromotionsPackageVipCanAddCode = 'dashboard.promotions_package_vip_can_add_code',
  PromotionsPackageVipCanAddPayment = 'dashboard.promotions_package_vip_can_add_payment',
  PromotionsPackageVipCanChangeSeller = 'dashboard.promotions_package_vip_can_change_seller',
  PromotionsPackageVipCanDelete = 'dashboard.promotions_package_vip_can_delete',
  PromotionsPackageVipCanDeletePayment = 'dashboard.promotions_package_vip_can_delete_payment',
  PromotionsPromocodeTouristVoucherCanCancel = 'dashboard.promotions_promocode_tourist_voucher_can_cancel',
  PromotionsSubscriptionCanChangeAutoCancelAfterDate = 'dashboard.promotions_can_change_auto_cancel_after_date',
  PromotionsSubscriptionContractCanEditConfirmed = 'dashboard.promotions_subscription_contract_can_edit_confirmed',
  PromotionsSubscriptionContractCanExecuteRecurringPayment = 'dashboard.promotions_subscription_contract_can_execute_recurring_payment',
  PromotionsSubscriptionContractCanRenewPromocode = 'dashboard.promotions_subscription_contract_can_renew_promocode',
  PromotionsSubscriptionContractCanResume = 'dashboard.promotions_subscription_contract_can_resume',
  ReceptionBookingCanCheckOutWithNotCheckoutPreauthorization = 'dashboard.reception_booking_can_check_out_with_not_checkout_preauthorization',
  ReceptionBookingCanTransferDeposit = 'dashboard.reception_booking_can_transfer_deposit',
  ReceptionCanExportLoadNoteReport = 'dashboard.reception_can_export_load_note_report',
  ReceptionKioskCanUseKiosk = 'dashboard.reception_kiosk_can_use_kiosk',
  ReceptionMassSmsCanIgnoreContentLimit = 'dashboard.reception_mass_sms_can_ignore_content_limit',
  RentBookingCanChangeDateWithForce = 'rent.can_change_date_with_force',
  RentBookingCanMarkAsExtendedStay = 'rent.can_mark_reservation_as_extended_stay',
  RentalProductCanManage = 'dashboard.rental_product_can_manage',
  ReportTechnicalOrderReport = 'dashboard.report_technical_order_report',
  ReportsAlternativeFeeding = 'dashboard.reports_alternative_feeding',
  ReportsApartmentMeter = 'dashboard.reports_apartment_meter_report',
  ReportsBookingParkingVip = 'dashboard.reports_booking_parking_vip_report',
  ReportsBookingReservationDiscount = 'dashboard.reports_booking_reservation_discount',
  ReportsCafeteriaCode = 'dashboard.reports_cafeteria_code_report',
  ReportsCanExportPayments = 'rent.can_export_payments',
  ReportsCanRestartReportTask = 'dashboard.reports_can_restart_report_task',
  ReportsCanViewStatsSaleItem = 'dashboard.reports_can_view_stats_sale_item',
  ReportsClimaticTaxReport = 'dashboard.reports_climatic_tax_report',
  ReportsCommonMeters = 'dashboard.reports_common_meters_report',
  ReportsContract = 'dashboard.reports_contract_report',
  ReportsEntranceTicketSuntago = 'dashboard.reports_entrance_ticket_suntago_report',
  ReportsEntranceTicketZoo = 'dashboard.reports_entrance_ticket_zoo_report',
  ReportsEntranceTicketZooMonthly = 'dashboard.reports_entrance_ticket_zoo_monthly_report',
  ReportsErliPromocodeUsageCanView = 'dashboard.reports_erli_promocode_usage_can_view',
  ReportsFaultOrder = 'dashboard.reports_fault_order',
  ReportsFeeding = 'dashboard.reports_feeding',
  ReportsFeedingClient = 'dashboard.reports_feeding_client',
  ReportsFeedingSale = 'dashboard.reports_feeding_sale_report',
  ReportsFeedingSpecialGuest = 'dashboard.reports_feeding_special_guest',
  ReportsFeedingWorkers = 'dashboard.reports_feeding_workers',
  ReportsFloorHeating = 'dashboard.reports_floor_heating_report',
  ReportsGastroCardBoost = 'dashboard.reports_gastro_card_boost_report',
  ReportsGastroCardVat = 'dashboard.reports_gastro_card_vat_report',
  ReportsGastroCoupon = 'dashboard.reports_gastro_coupon_report',
  ReportsHousekeepingCleaningOrders = 'dashboard.reports_cleaning_order',
  ReportsHousekeepingUser = 'dashboard.reports_housekeeping_user_report',
  ReportsHousekeepingUsers = 'dashboard.reports_housekeeping_users_report',
  ReportsIssueOrder = 'dashboard.reports_issue_order',
  ReportsMarketingRodo = 'dashboard.reports_marketing_rodo_report',
  ReportsMarketingRodoCancel = 'dashboard.reports_marketing_rodo_cancel_report',
  ReportsPackage = 'dashboard.reports_package',
  ReportsPackageCode = 'dashboard.reports_package_code',
  ReportsPackageHpi = 'dashboard.reports_package_hpi',
  ReportsPackageHpiFullCode = 'dashboard.reports_package_hpi_full_code_report',
  ReportsPackageHprFullCode = 'dashboard.reports_package_hpr_full_code_report',
  ReportsPackageWholesale = 'dashboard.reports_package_wholesale',
  ReportsPackageWholesaleCode = 'dashboard.reports_package_wholesale_code_report',
  ReportsPermission = 'dashboard.reports_permission_report',
  ReportsPromocodeTouristVoucher = 'dashboard.reports_promocode_tourist_voucher_report',
  ReportsReceiptProduct = 'dashboard.reports_receipt_product_report',
  ReportsReservationBill = 'dashboard.reports_reservation_bill_report',
  ReportsReservationBillItem = 'dashboard.reports_reservation_bill_item_report',
  ReportsReservationLatePayment = 'dashboard.reports_reservation_late_payment_report',
  ReportsResortsBand = 'dashboard.reports_resort_bands_report',
  ReportsSMS = 'dashboard.reports_sms_report',
  ReportsShopProductCanView = 'dashboard.reports_shop_product_can_view',
  ReportsSubscriptionContract = 'dashboard.subscription_contract_report',
  ReportsSubscriptionConversionPrivateReport = 'dashboard.reports_subscription_conversion_private',
  ReportsSubscriptionConversionViewReport = 'dashboard.reports_subscription_conversion_view_report',
  ReportsSubscriptionConversionWholesaleReport = 'dashboard.reports_subscription_conversion_wholesale',
  ReportsSubscriptionConversionWithBenefitsReport = 'dashboard.reports_subscription_conversion_with_benefits',
  ReportsGenericVoucherCodeReport = 'dashboard.reports_generic_voucher_code_report',
  ReportsCourtyardOccupancyReport = 'dashboard.reports_courtyard_occupancy_report',
  ReportsCashbackVoucherReport = 'dashboard.reports_cashback_voucher_report',
  ServiceBandCanCreate = 'dashboard.service_band_can_create',
  ServiceBandCanDelete = 'dashboard.service_band_can_delete',
  ServiceBandCanUpdate = 'dashboard.service_band_can_update',
  ServiceBandCanView = 'dashboard.service_band_can_view',
  ShopCanExportReceipts = 'dashboard.shop_can_export_receipts',
  SubscriptionCanAddOptionsAfterPayment = 'dashboard.subscription_can_add_options_after_payment',
  SubscriptionCanAddSuntagoTickets = 'dashboard.subscription_can_add_suntago_tickets',
  SubscriptionCanAddZooTickets = 'dashboard.subscription_can_add_zoo_tickets',
  SubscriptionCanCancel = 'dashboard.can_subscription_cancel',
  SubscriptionCanRevertCodeConversion = 'dashboard.can_revert_code_conversion',
  SubscriptionCanChangeClient = 'dashboard.subscription_can_change_client',
  SubscriptionCanGenerateReport = 'dashboard.subscription_can_generate_report',
  SubscriptionCanSetDepositAmount = 'dashboard.subscription_can_set_deposit_amount',
  SubscriptionCanSetExpireAfter = 'dashboard.subscription_can_set_expire_after',
}

interface SystemNotificationUrls {
  update: string
  delete: string
}

export interface SystemNotification {
  created: string
  created_by: string
  id: string
  kind: string
  kind_display: string
  message: string
  read: boolean
  title: string
  url: string
  urls: SystemNotificationUrls
}

export enum NotificationEvents {
  APARTMENT_CHANGE = 'APARTMENT_CHANGE',
  BAND_READER_RESULT = 'BAND_READER_RESULT',
  BARRIER_TICKET_CHANGE = 'BARRIER_TICKET_CHANGE',
  BARRIER_TICKET_REMOVE = 'BARRIER_TICKET_REMOVE',
  BEDDING_ORDER_CHANGE = 'BEDDING_ORDER_CHANGE',
  BOOKING_ADD = 'BOOKING_ADD',
  BOOKING_CHANGE = 'BOOKING_CHANGE',
  BOOKING_REFUND_ADD = 'BOOKING_REFUND_ADD',
  BOOKING_REFUND_CHANGE = 'BOOKING_REFUND_CHANGE',
  BOOKING_REMOVE = 'BOOKING_REMOVE',
  CAFETERIA_CHANGE = 'CAFETERIA_CHANGE',
  CHECK_STATS_CHANGE = 'CHECK_STATS_CHANGE',
  CLEANING_ORDER_CHANGE = 'CLEANING_ORDER_CHANGE',
  CLEANING_ORDER_TASK_REORDERED = 'CLEANING_ORDER_TASK_REORDERED',
  CLIENT_CHANGE = 'CLIENT_CHANGE',
  COMMON_TASK_CHANGE = 'COMMON_TASK_CHANGE',
  ESERVICE_PIN_PAD_ACTION_CHANGE = 'ESERVICE_PIN_PAD_ACTION_CHANGE',
  FAULT_ORDER_CHANGE = 'FAULT_ORDER_CHANGE',
  FEEDING_CHANGE = 'FEEDING_CHANGE',
  GASTRO_CARD_CHANGE = 'GASTRO_CARD_CHANGE',
  IMPROVEMENT_ORDER_CHANGE = 'IMPROVEMENT_ORDER_CHANGE',
  ISSUE_ORDER_CHANGE = 'ISSUE_ORDER_CHANGE',
  PACKAGE_APP_DATA_STATS_UPDATE = 'PACKAGE_APP_DATA_STATS_UPDATE',
  PACKAGE_CHANGE = 'PACKAGE_CHANGE',
  TECHNICAL_ORDER_CHANGE = 'TECHNICAL_ORDER_CHANGE',
  PACKAGE_VIP_CHANGE = 'PACKAGE_VIP_CHANGE',
  PACKAGE_WHOLESALE_CHANGE = 'PACKAGE_WHOLESALE_CHANGE',
  PACKAGE_WHOLESALE_PRODUCT_CHANGE = 'PACKAGE_WHOLESALE_PRODUCT_CHANGE',
  PAYMENT_REGISTER_CHANGE = 'PAYMENT_REGISTER_CHANGE',
  PAYMENT_REPORT_DAY_CHANGE = 'PAYMENT_REPORT_DAY_CHANGE',
  PRODUCT_CHANGE = 'PRODUCT_CHANGE',
  RECEIPT_CHANGE = 'RECEIPT_CHANGE',
  RECOMMENDATION_GIFT_ADD = 'RECOMMENDATION_GIFT_ADD',
  RECOMMENDATION_GIFT_CHANGE = 'RECOMMENDATION_GIFT_CHANGE',
  RENTAL_PRODUCT_ADD = 'RENTAL_PRODUCT_ADD',
  RENTAL_PRODUCT_CHANGE = 'RENTAL_PRODUCT_CHANGE',
  REPORT_TASK_CHANGE = 'REPORT_TASK_CHANGE',
  SELLER_CHANGE = 'SELLER_CHANGE',
  SUBSCRIPTION_CHANGE = 'SUBSCRIPTION_CHANGE',
  SUBSCRIPTION_CONTRACT_CHANGE = 'SUBSCRIPTION_CONTRACT_CHANGE',
  SUBSCRIPTION_PRODUCT_CHANGE = 'SUBSCRIPTION_PRODUCT_CHANGE',
  SYSTEM_NOTIFICATION_CHANGE = 'SYSTEM_NOTIFICATION_CHANGE',
  AGREEMENT_NOTIFICATION_CHANGE = 'AGREEMENT_NOTIFICATION_CHANGE',
}

interface ContentNoteImage {
  id: number
  image: string
}

export interface ContentNote {
  is_removed: boolean
  is_pinned: boolean
  pinned_datetime: string | null
  can_change: boolean
  can_remove: boolean
  content: string
  images?: ContentNoteImage[]
  created: string
  created_by: string
  id: number
  urls: BaseUrls
}

export interface BaseHistoryParamsChange {
  field_name: string
  field_name_display: string
  new_value: any
  new_value_display: string
  old_value: any
  old_value_display: string
}

interface BaseHistoryParams {
  changes?: BaseHistoryParamsChange[]
}

export interface BaseHistory {
  id: number
  keyword_display: string
  keyword: string
  author: string | null
  created_at: string
  message: string
  link: string
  send_to?: string
  params: BaseHistoryParams
}

interface DocumentUrls {
  details: string
  download: string
}

export interface Document {
  id: number
  created_by: string
  created: string
  description: string
  file_name: string
  urls: DocumentUrls
}

export interface AuthenticatedUser {
  is_hijacked: boolean
  is_technical_order_manager: boolean
  is_technical_order_worker: boolean
  id: number
  first_name: string
  last_name: string
  email: string
  resort_ids: number[]
  permissions: UserPermission[]
  system_notifications: SystemNotification[]
  is_superuser: boolean
  date_joined: string
  is_receptionist: boolean
  payment_day_user_report: null | PaymentDayUserReport
  reception_workstation_id: number | null
  seller_id: number
  eservice_pad_id: number | null
  band_reader_id: number | null
  tablet_id: number | null
}

export interface DashboardStandardPaginator {
  count: number
  next: string | null
  previous: string | null
  page_link: string
  skip_paginator?: boolean
}

export interface PaginationResponse<T> extends DashboardStandardPaginator {
  results: T[]
}

export interface PaginationResponseWithAggregation<T, V> extends PaginationResponse<T> {
  aggregation: V
}

export interface ListDataWithAggregation<T, A> {
  results: T
  aggregation: A
}

export interface DashboardCheckStats {
  day_in: number
  day_in_realized: number
  day_out: number
  day_out_realized: number
  day_out_in: number
  day_out_remained: number
  day_in_remained: number
  resort_id: number
}

interface DashboardUrlsSaleState {
  app_data: string
  nip_search: string
  cash_box_print: string
  cash_box_status: string
  cash_registers: string
}

export interface DashboardUrlSubscriptionContractState {
  subscription_contracts: string
}

interface DashboardUrlPromotionsState {
  app_data: string
  booking_option: string
  client_wallet_assigment: string
  client_wallet_code_details: string
  gastro_cards: string
  gastro_cards_code_add: string
  gastro_coupon_validate_booking: string
  gastro_generic: string
  package_vip: string
  package_vip_booking_improvement: string
  promocode_tourist_vouchers: string
}

interface DashboardUrlCrmState {
  sellers: string
  recommendation_gifts: string
  app_data: string
}

interface DashboardUrlClientsState {
  clients: string
}

interface DashboardUrlCoupons {
  summary: string
  types: string
  types_configurations: string
  list: string
}

interface DashboardUrlAccountState {
  details: string
  profile: string
  password: string
  logout: string
  users: string
  groups: string
  housekeeping_users: string
  receptionist_users: string
  call_center_users: string
}

interface DashboardUrlRcpState {
  users: string
  export_xls: string
  export_pdf: string
}

interface DashboardUrlReceptionState {
  app_data: string
  booking_details: string
  booking_export: string
  bookings: string
  bookings_aggregation: string
  mass_sms_notification: string
  reset_kiosk: string
}

interface DashboardUrlRentState {
  app_data: string
  apartment_block: string
  apartment_list: string
  available_apartments: string
  booking_autocomplete: string
  booking_export: string
  booking_improvement_stats: string
  booking_list: string
  booking_stats: string
  booking_stats_app_data: string
  booking_stats_board: string
  calendar_bookings: string
  promocode_create: string
  promocode_email_templates: string
  promocode_groups: string
  promocode_list: string
  promocode_prefixes: string
}

interface DashboardUrlsDashboardState {
  global_search: string
  hijack_release: string
  content_note_create: string
  quick_search_url: string
  system_notification_mass_api: string
}

interface DashboardUrlsReportsState {
  housekeeping_users_report: string
  media_report_items: string
  payment_report: string
  report_package_wholesale_code_report: string
  report_promocode_group_codes: string
  report_task_apartment_meter_report: string
  report_task_apartment_occupancy_stats_report: string
  report_task_booking_check: string
  report_task_booking_collision_stats_report: string
  report_task_booking_improvement_stats_global_report: string
  report_task_booking_improvement_stats_report: string
  report_task_booking_online_check_in_stats_report: string
  report_task_booking_online_check_out_stats_report: string
  report_task_booking_parking_vip: string
  report_task_booking_payment: string
  report_task_booking_reservation_discount: string
  report_task_booking_stats_report: string
  report_task_cafeteria_code: string
  report_task_cash_register: string
  report_task_cleaning_order_report: string
  report_task_clients: string
  report_task_climatic_tax_report: string
  report_task_common_meters_report: string
  report_task_contract_report: string
  report_task_entrance_ticket_suntago_report: string
  report_task_entrance_ticket_zoo_report: string
  report_task_erli_code: string
  report_task_fault_order: string
  report_task_feeding_alternative: string
  report_task_feeding_client: string
  report_task_feeding_sale: string
  report_task_feeding_special_guest: string
  report_task_feeding_workers: string
  report_task_floor_heating_report: string
  report_task_gastro_card: string
  report_task_gastro_card_boost_report: string
  report_task_gastro_card_vat_report: string
  report_task_gastro_coupon_report: string
  report_task_courtyard_occupancy_report: string
  report_task_cashback_voucher_report: string
  report_task_generic_voucher_code_report: string
  report_task_housekeeping_user_report: string
  report_task_housekeeping_users_report: string
  report_task_issue_order: string
  report_task_load_note: string
  report_task_marketing_rodo_cancel_report: string
  report_task_marketing_rodo_report: string
  report_task_package: string
  report_task_package_code: string
  report_task_package_full_code: string
  report_task_package_hpi: string
  report_task_package_hpi_code: string
  report_task_package_hpi_full_code: string
  report_task_package_vip: string
  report_task_package_wholesale: string
  report_task_payment_move: string
  report_task_payment_register: string
  report_task_permission: string
  report_task_promocode_tourist_voucher_report: string
  report_task_receipt: string
  report_task_receipt_product: string
  report_task_reservation_bill_item_report: string
  report_task_reservation_bill_report: string
  report_task_reservation_late_payment_report: string
  report_task_reservation_stats: string
  report_task_reservations: string
  report_task_resort_bands_report: string
  report_task_shop_product: string
  report_task_sms_report: string
  report_task_stats_sale_items: string
  report_task_subscription: string
  report_task_subscription_code: string
  report_task_subscription_contract_report: string
  report_task_subscription_conversion_report: string
  report_task_subscription_payment: string
  report_task_technical_order_report: string
  reports_app_data: string
  stats_sale_item: string
  stats_sale_item_dashboard: string
  stats_sale_item_dashboard_report: string
  stats_sale_item_user: string
  user_reports: string
}

interface DashboardUrlBandsState {
  band_details: string
  service_bands: string
  reader_request: string
  reader_encode: string
  encode_band: string
}

interface DashboardUrlWalletState {
  account: string
  virtual_pos: string
}

interface DashboardUrlPackageState {
  calculate: string
  package_app_data: string
  packages: string
}

interface DashboardUrlSubscriptionState {
  subscription_app_data: string
  subscription_products: string
  subscriptions: string
}

interface DashboardUrlShopState {
  app_data: string
  booking_seller_day_visits: string
  documents: string
  products: string
  sell_product_calculation: string
  receipts: string
  receipt_create: string
}

interface DashboardUrlsErliState {
  promocode_list: string
}

interface DashboardUrlsHousekeepingState {
  units: string
  cleaning_orders: string
  app_data: string
  application_image: string
  applications_aggregation: string
  bedding_orders_list: string
  cleaning_order_create: string
  cleaning_orders_aggregation: string
  cleaning_orders_list: string
  common_tasks_list: string
  fault_orders: string
  improvement_orders: string
  improvement_orders_provider: string
  issue_order_bookings: string
  issue_orders: string
}

interface DashboardUrlsFeedingState {
  apartments: string
  app_data: string
  clients: string
  clients_calculate: string
  feeding_price_list: string
  enter: string
  print: string
  search: string
  special_guests: string
  special_guests_list: string
  stats: string
  worker_guests: string
  workers_list: string
}

interface DashboardUrlAccountingState {
  booking_refunds: string
  bookings_client_data_notification: string
  transfer_package_create: string
  transfer_packages: string
}

interface DashboardUrlNotificationsState {
  email_templates: string
  sms_templates: string
}

interface DashboardUrlCmsState {
  asset_categories: string
  assets: string
  accommodation_types: string
  app_data: string
  improvements_position: string
  improvements: string
  resorts: string
  resorts_position: string
  resorts_attractions: string
  resorts_faqs: string
  text_blocks: string
}

interface DashboardUrlReservationState {
  available_apartments: string
  calendar: string
  reservation_calculate_price: string
  reservation_check_accommodation_type: string
  reservation_check_dates: string
  reservation_create_unfinished_booking: string
}

interface DashboardUrlPaymentState {
  count_money_report: string
  payment_app_data: string
  payment_day_report: string
  payment_registers: string
  safebox_stats: string
}

interface DashboardUrlsPackageWholesaleState {
  products: string
  app_data: string
  package_wholesales: string
}

interface DashboardUrlsEserviceState {
  pin_pad_actions: string
}

interface DashboardUrlBarriersState {
  tickets: string
  tickets_special: string
}

interface DashboardUrlsClientOptionsState {
  client_options_purchases: string
  client_options_available_options: string
}

interface DashboardUrlsProductsState {
  app_data: string
}

interface DashboardUrlsRentalState {
  app_data: string
  products: string
  products_import: string
  product_categories: string
  warehouses: string
}

interface DashboardUrlHrState {
  agreements: string
  app_data: string
  hr_workers: string
  hr_worker_profile_extended_list: string
}

interface DashboardUrlCafeteriaState {
  app_data: string
  cafeterias: string
}

interface DashboardUrlsMeters {
  common_meters_devices: string
  common_meters_locations: string
  floor_heating_measurements_list: string
  floor_heating_scenarios_list: string
  floor_heating_windows_list: string
  floor_heating_measurements: string
  floor_heating_app_data: string
}

interface DashboardUrlsUpselling {
  app_data: string
  upselling_bookings: string
  upselling_bookings_outdated: string
}

interface DashboardUrlsTechnicalOrders {
  app_data: string
  technical_orders: string
  guests_technical_orders: string
  reception_technical_orders: string
}

interface DashboardEcommerceUrls {
  stats: string
}

interface DashboardDatasetsUrls {
  address: string
}

interface DashboardUrlsCashback {
  app_data: string
  cashback_vouchers: string
}

export interface DashboardRulesUrls {
  rodo_agreements: string
}

interface DashboardGenericVouchersUrls {
  generic_vouchers: string
}

interface DashboardUrlsBenefitPrograms {
  benefit_program_agreements: string
  benefit_programs: string
  app_data: string
}

export interface DashboardUrlsProductPackage {
  invite: string
  product_package_invite_list: string
  product_package_list: string
}

export interface DashboardUrlsState {
  benefit_programs: DashboardUrlsBenefitPrograms
  upselling: DashboardUrlsUpselling
  technical_orders: DashboardUrlsTechnicalOrders
  account: DashboardUrlAccountState
  accounting: DashboardUrlAccountingState
  bands: DashboardUrlBandsState
  barriers: DashboardUrlBarriersState
  cafeteria: DashboardUrlCafeteriaState
  cashback: DashboardUrlsCashback
  clients: DashboardUrlClientsState
  coupons: DashboardUrlCoupons
  cms: DashboardUrlCmsState
  crm: DashboardUrlCrmState
  dashboard: DashboardUrlsDashboardState
  erli: DashboardUrlsErliState
  eservice: DashboardUrlsEserviceState
  feeding: DashboardUrlsFeedingState
  housekeeping: DashboardUrlsHousekeepingState
  hr: DashboardUrlHrState
  meter: DashboardUrlsMeters
  notifications: DashboardUrlNotificationsState
  package: DashboardUrlPackageState
  package_wholesale: DashboardUrlsPackageWholesaleState
  payment: DashboardUrlPaymentState
  products: DashboardUrlsProductsState
  client_options: DashboardUrlsClientOptionsState
  promotions: DashboardUrlPromotionsState
  rcp: DashboardUrlRcpState
  reception: DashboardUrlReceptionState
  rent: DashboardUrlRentState
  rental: DashboardUrlsRentalState
  reports: DashboardUrlsReportsState
  reservation: DashboardUrlReservationState
  sale: DashboardUrlsSaleState
  shop: DashboardUrlShopState
  subscription: DashboardUrlSubscriptionState
  subscription_contract: DashboardUrlSubscriptionContractState
  wallet: DashboardUrlWalletState
  ecommerce: DashboardEcommerceUrls
  datasets: DashboardDatasetsUrls
  rules: DashboardRulesUrls
  generic_vouchers: DashboardGenericVouchersUrls
  product_packages: DashboardUrlsProductPackage
}

interface DashboardMenuBadge {
  value: number
  style: string
}

export interface DashboardMenuItem {
  label: any
  url: string
  badge?: DashboardMenuBadge
  separator?: boolean
}

export interface DashboardMenu {
  url?: string
  label: string
  icon: string
  baseurl: string
  models: DashboardMenuItem[]
  badge?: DashboardMenuBadge
}

interface DashboardContentTypes {
  benefitprogramagreement: number
  benefitprogram: number
  genericvoucher: number
  agreement: number
  booking: number
  cafeteria: number
  cashbackvoucher: number
  gastrocard: number
  package: number
  packagevip: number
  packagewholesale: number
  promocodetouristvoucher: number
  receipt: number
  subscription: number
  subscriptioncontract: number
  technicalorder: number
  transferpackage: number
  upsellbooking: number
  user: number
  clientoptionpurchase: number
  hrworkerprofile: number
  productpackage: number
}

export interface PeopleKind {
  name: string
  id: string
}

type DashboardMonth = [number, string]

interface DashboardAppDataDates {
  seasons: number[]
  months: DashboardMonth[]
}

interface DashboardAppDataConfiguration {
  cashback_vouchers: boolean
  vzk_enabled: boolean
  reservation_booking_damage_bands_price_brutto: string
  reservation_booking_damage_keys_price_brutto: string
  reservation_deposit_available_values: number[]
  reservation_deposit_default_amount: number
}

interface BookingSource {
  id: string
  name: string
}

export interface Country {
  id: string
  name: string
}

export interface DashboardSubscription {
  statuses: [string, string][]
  sources: [string, string][]
  sellers: [string, [string, string][] | string][]
}

export interface PaymentSource {
  id: string
  name: string
}

export interface VoucherScope {
  id: string
  name: string
}

interface DashboardAppDataSettings {
  subscription_client_data_change_price: string
  subscription_disposable_localization_change_price: string
  subscription_upgrade_to_purple_prices: string[]
}

export interface DashboardTag {
  code: string
  id: number
  name: string
}

export interface DashboardAppDataWithUser extends DashboardAppData {
  user: AuthenticatedUser | null
}

interface HistoryField {
  [key: string]: string
}

export type DepositPaymentDeadline = 'now' | '12_months' | '30_days'

export interface DashboardAppData {
  user_languages: [string, string][]
  unit_kinds: UnitKind[]
  units: Unit[]
  deposit_payment_deadline: [DepositPaymentDeadline, string][]
  has_apartment_meter_non_apartments: number
  tags: DashboardTag[]
  call_center_groups: Group[]
  accommodation_types: AccommodationType[]
  booking_sources: BookingSource[]
  payment_sources: PaymentSource[]
  voucher_scopes: VoucherScope[]
  check_stats: DashboardCheckStats[]
  configuration: DashboardAppDataConfiguration
  content_types: DashboardContentTypes
  countries: Country[]
  dates: DashboardAppDataDates
  menu: DashboardMenu[]
  people_kinds: PeopleKind[]
  status: 'unknown' | 'ready' | 'loading'
  resorts: Resort[]
  receipt_unfinished: Receipt | null
  subscription: DashboardSubscription
  urls: DashboardUrlsState
  settings: DashboardAppDataSettings
  band_readers: BandReader[]
  eservice_pin_pads: EservicePinPad[]
  reception_workstations: ReceptionWorkstation[]
  tablets: Tablet[]
  history_fields: HistoryField[]
}

import * as React from 'react'
import { ColAuto } from '@hyper/col-auto'
import { Button, FormGroup, Label, Row } from 'reactstrap'
import { CustomReactSelectOption } from '@components/custom-react-select'
import { FormProvider, useForm } from 'react-hook-form'
import { subscriptionStatusList, SubscriptionType } from '@modules/subscription/models'
import { useGroupedSources } from '@components/hooks/use-grouped-sources'
import { useGroupedSellers } from '@components/hooks/use-grouped-sellers'
import DateRangeInput from '@components/date/date-range-input'
import { SearchFilter } from '@modules/reception/common/filters/search-filter'
import { useHandleTableFiltersChange } from '@components/table/use-handle-table-filters-change'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'
import { createSelectOption } from '@helpers/utils'
import { useSubscriptionKinds } from '@modules/subscription/hooks/use-subscription-kinds'
import { FormSelect } from '@hyper/forms/form-select'
import { defaultFrontendDate } from '@helpers/date-helper'

export interface SubscriptionListFilterParams {
  status: CustomReactSelectOption[] | undefined
  source_marketing: CustomReactSelectOption | undefined
  seller: CustomReactSelectOption | undefined
  accommodation_type: CustomReactSelectOption | null
  kind: CustomReactSelectOption[]
  option_kinds: CustomReactSelectOption[]
  created_after: Date | undefined
  created_before: Date | undefined
  search: string
  ordering: string
  page: number
  page_size: number
}

interface Props {
  defaultFilters: SubscriptionListFilterParams
  filters: SubscriptionListFilterParams
  setFilters: (filters: SubscriptionListFilterParams) => void
  children: React.ReactNode
  statusList?: CustomReactSelectOption[]
  type: SubscriptionType
}

export const SubscriptionListFilter: React.FC<Props> = ({
  children,
  defaultFilters,
  filters,
  setFilters,
  statusList = subscriptionStatusList,
  type,
}) => {
  const { subscription_option_kinds, subscription_product_sets } = useSubscriptionAppData()

  const methods = useForm<SubscriptionListFilterParams>({
    defaultValues: defaultFilters,
  })
  const { subscriptionKindOptions } = useSubscriptionKinds()

  const handleReset = () => {
    methods.reset()
    setFilters(defaultFilters)
  }

  const onSubmit = async (payload: SubscriptionListFilterParams) => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, onSubmit)

  const sources = useGroupedSources()
  const sellers = useGroupedSellers()
  const subscriptionOptions = subscription_option_kinds.map(option => createSelectOption(option.value, option.key))
  const subscriptionProductSetOptions = subscription_product_sets.map(option =>
    createSelectOption(option.name, option.id),
  )

  return (
    <FormProvider {...methods}>
      <Row>
        <ColAuto className="px-0">
          <FormSelect
            options={statusList}
            name="status"
            label="Status"
            selectProps={{ isSelectMulti: true, isClearable: true }}
            formPlainProps={{ colStyle: { width: 330 } }}
          />
        </ColAuto>
        <ColAuto className="px-0">
          <FormSelect
            options={sources}
            name="source_marketing"
            label="Źródło"
            formPlainProps={{ colStyle: { width: 200 } }}
          />
        </ColAuto>
        <ColAuto className="px-0">
          <FormSelect
            options={sellers}
            name="seller"
            label="Sprzedawca"
            formPlainProps={{ colStyle: { width: 200 } }}
          />
        </ColAuto>

        <FormSelect
          options={AccommodateTypeOptions}
          name="accommodation_type"
          label="Zakwaterowanie"
          formPlainProps={{ colSize: 1 }}
        />

        {type === 'DEFAULT' && (
          <FormSelect
            options={subscriptionProductSetOptions}
            name="subscription_product_set"
            label="Zestaw"
            formPlainProps={{ colSize: 2 }}
          />
        )}

        <ColAuto>
          <FormGroup>
            {methods.formState.isDirty && (
              <Button color="light" className="ml-1 mt-label" type="button" onClick={handleReset}>
                Wyczyść
              </Button>
            )}
          </FormGroup>
        </ColAuto>

        {children}
      </Row>
      <Row>
        <ColAuto>
          <Label>Data utworzenia</Label>
          <DateRangeInput
            isClearable={true}
            startDateName="created_after"
            endDateName="created_before"
            dateFormat={defaultFrontendDate}
          />
        </ColAuto>
        <ColAuto>
          <Label>Data wpłaty</Label>
          <DateRangeInput
            isClearable={true}
            startDateName="payment_date_after"
            endDateName="payment_date_before"
            dateFormat={defaultFrontendDate}
          />
        </ColAuto>
        <ColAuto>
          <FormSelect
            options={subscriptionKindOptions}
            name="kind"
            label="Rodzaj pakietu"
            formPlainProps={{ colStyle: { width: 150 } }}
          />
        </ColAuto>

        {type !== 'BOOKING_IMPROVEMENT' && (
          <ColAuto>
            <FormSelect
              options={subscriptionOptions}
              name="option_kinds"
              label="Dodane opcje"
              selectProps={{ isClearable: true, isSelectMulti: true }}
              formPlainProps={{ colStyle: { width: 330 } }}
            />
          </ColAuto>
        )}

        <SearchFilter colClassName="col-md-2" />
      </Row>
    </FormProvider>
  )
}

export const AccommodateTypeOptions = [createSelectOption('Domek', 1), createSelectOption('Apartament', 2)]

import * as React from 'react'
import { FormInput, FormPlain } from '@hyper/forms'
import { Row } from 'reactstrap'
import { useFormContext, useWatch } from 'react-hook-form'
import { SubscriptionCreateFormInputs } from '@modules/subscription/create/modal'
import { useSubscriptionAppData } from '@modules/subscription/hooks/use-subscription-app-data'
import * as R from 'ramda'
import { SubscriptionOptionKindPrice, SubscriptionProduct, SubscriptionType } from '@modules/subscription/models'
import { formatPriceShort, getById } from '@helpers/utils'
import DatePickerInput from '@components/date/date-picker-input'
import { useAuthenticatedUser } from '@components/hooks/use-authenticated-user'
import { UserPermission } from '@models/dashboard'
import { add, startOfToday } from 'date-fns'
import { useDidUpdateEffect } from '@components/hooks/use-did-update-effect'
import { SubscriptionOptionsForm } from '@modules/subscription/create/options/options-form'
import { useSubscriptionTypeStrategyProvider } from '@modules/subscription/hooks/use-subscription-type-provider'
import { SubscriptionCreateFormAdditionalClient } from '@modules/subscription/create/steps/additional-client'

interface Props {
  type: SubscriptionType
}

export const SubscriptionCreateFormParams: React.FC<Props> = ({ type }) => {
  const { control, setValue } = useFormContext<SubscriptionCreateFormInputs>()
  const appData = useSubscriptionAppData()
  const user = useAuthenticatedUser()
  const grouper = R.groupBy<SubscriptionProduct, string>(R.prop('client_kind_display'))
  const productGroups = Object.values(
    grouper(appData.products.filter(row => row.status === 'available' && row.type == type)),
  )

  const selectedProductId = useWatch({ control, name: 'product' })
  const selectedProduct = getById(appData.products, selectedProductId)

  const { allow_change_expire_after, allow_add_additional_clients } = useSubscriptionTypeStrategyProvider(type)

  useDidUpdateEffect(() => {
    if (!selectedProduct) {
      setValue('allow_subscription_code_localization_convert', false)
      setValue('allow_change_client_data', false)
      setValue('with_zoo_borysew_tickets', false)
      setValue('clients', [])
    }

    if (selectedProduct && selectedProduct.default_expire_years) {
      setValue('expire_after', add(startOfToday(), { years: selectedProduct.default_expire_years }))
      setValue('deposit_amount', selectedProduct.deposit_amount)
    }
  }, [selectedProduct])

  const prices: SubscriptionOptionKindPrice = {
    zoo_borysew: selectedProduct?.zoo_borysew_tickets_single_price_brutto ?? '',
    suntago: selectedProduct?.suntago_tickets_single_price_brutto ?? '',
    localization: selectedProduct ? formatPriceShort(selectedProduct.code_localization_convert_price_brutto) : '',
    client_data: selectedProduct ? formatPriceShort(selectedProduct.change_client_data_price_brutto) : '',
  }

  return (
    <Row>
      <FormInput type="select" label="Produkt" colSize={6} name="product" registerParams={{ valueAsNumber: true }}>
        <option value={undefined}>wybierz</option>
        {productGroups.map((products: SubscriptionProduct[]) => (
          <optgroup key={products[0].client_kind_display} label={products[0].client_kind_display}>
            {products.map(product => (
              <option value={product.id} key={product.id}>
                {product.name}
              </option>
            ))}
          </optgroup>
        ))}
      </FormInput>

      {(allow_add_additional_clients || selectedProduct?.allow_add_second_client) && (
        <SubscriptionCreateFormAdditionalClient />
      )}

      <Row className="w-100 mx-0">
        <FormInput
          readOnly={!user.hasPerm(UserPermission.SubscriptionCanSetDepositAmount) || !selectedProduct}
          label="Kwota kaucji"
          colSize={6}
          type="currency"
          name="deposit_amount"
        />

        {allow_change_expire_after && (
          <FormPlain colSize={6} name="expire_after" label="Data ważności">
            <DatePickerInput
              minDate={startOfToday()}
              isDisabled={!user.hasPerm(UserPermission.SubscriptionCanSetExpireAfter)}
              name="expire_after"
              wrapperClassName="flex-grow-1"
            />
          </FormPlain>
        )}
      </Row>

      <SubscriptionOptionsForm type={type} isEditDisabled={!selectedProduct} prices={prices} />

      <div className="w-100">
        <div className="bg-grey p-2" style={{ marginInline: -4 }}>
          <div className="fw-semi-bold font-16 text-right">
            Cena brutto {formatPriceShort(selectedProduct?.price_brutto ?? 0)}
          </div>
        </div>
      </div>
    </Row>
  )
}
